// Here you can add other styles
.float-spinner {
  z-index: 999;
  position: fixed;
  top: 25%;
  left: 50%;
}

.pagination{
  justify-content: center;
}

.fullscreen {
  z-index: 999999;
  position: absolute;
}

.react-datepicker__input-container {
  position: inherit !important;
  display: inline !important;
}

.react-datepicker__close-icon {
  right: 25px !important;
}

.react-datepicker__close-icon::after  {
  background-color: #3cb5bf !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: #3cb5bf !important;
}

.react-datepicker__day--selected {
  background-color: #3cb5bf !important;
}

.react-datepicker__time-list-item--selected {
  background-color: #3cb5bf !important;
}

button:focus {outline:0;}


.sidebar .nav-dropdown.open > .nav-dropdown-items a {
  padding-left: 30px;
}

.table-responsive th {
  min-width: 120px;
}

.table-responsive td {
  max-width: 300px;
}

.table-responsive audio {
  width: 270px;
}